import { useEffect } from "react";

export const SsoAuthentication = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const token = urlParams.get("token");

  useEffect(() => {
    if (token) {
      window.parent.postMessage(
        { type: "trigger", message: "success", data: token },
        "*"
      );
    } else {
      window.parent.postMessage(
        { type: "trigger", message: "error", data: window.location.href },
        "*"
      );
    }
  }, [token]);

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        height: "100vh",
        justifyContent: "center",
      }}
    >
      <h1>Loading...</h1>
    </div>
  );
};
