import React from "react";
import ReactDOM from "react-dom/client";
import { RecoilRoot } from "recoil";

import reportWebVitals from "./reportWebVitals";
import { Main } from "./main";
import { PlaidWebToMobile } from "./views/plaidWebToMobile/plaidWebToMobile";
import { SsoAuthentication } from "views/sso-login/auth";
const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

// Awadhesh: This is a temporary fix to load the PlaidWebToMobile component when the pathname includes "plaid-web-to-mobile".
// This is because the PlaidWebToMobile component is not being loaded in the App component.
const isPlaidMobileIntegrated = window.location.search.includes(
  "plaid-web-to-mobile"
);

const isSsoAuthIntegrated = window.location.href.includes(
  "auth"
);

const renderComponent = () => {
  if (isSsoAuthIntegrated) return <SsoAuthentication />;
  if (isPlaidMobileIntegrated) return <PlaidWebToMobile />;
  return <Main />;
};

root.render(
  <React.StrictMode>
    <RecoilRoot>
      {renderComponent()}
    </RecoilRoot>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
