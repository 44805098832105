import { REACT_APP_API_HOST } from "../../envs";
import { atom } from "recoil";

export const PlaidTokenState = atom<string>({
  key: "plaid-token-state-key",
  default: "",
});

// For liquidity mobile app - Do not change the api host
const PLAID_API_STAGE = "https://api.stage.satschel.com/v1";
const PLAID_API_PP = "https://api.pp.satschel.com/v1";
const PLAID_API_BETA = "https://api.beta.satschel.com/v1";
const PLAID_API_PROD = "https://api.satschel.com/v1";

export let PLAID_API_HOST = PLAID_API_PROD;

if (REACT_APP_API_HOST.includes("stage")) {
  PLAID_API_HOST = PLAID_API_STAGE;
} else if (REACT_APP_API_HOST.includes("pp")) {
  PLAID_API_HOST = PLAID_API_PP;
} else if (REACT_APP_API_HOST.includes("beta")) {
  PLAID_API_HOST = PLAID_API_BETA;
} else {
  PLAID_API_HOST = PLAID_API_PROD;
}
