import { useCallback, useState } from "react";
import {
  PlaidLinkOnSuccess,
  PlaidLinkOptions,
  usePlaidLink,
} from "react-plaid-link";
import { useRecoilState } from "recoil";
import { PLAID_API_HOST, PlaidTokenState } from "./states";

export const useAppPlaidLink = (apiConfig?: any) => {
  const [token, setToken] = useRecoilState(PlaidTokenState);
  const [isBankProceedLoader, setIsBankProceedLoader] = useState(false);

const onSuccess = useCallback<PlaidLinkOnSuccess>(
  async (publicToken, metadata) => {
    let payload = {
      bankName: metadata.institution?.name,
      linkToken: token,
      token: publicToken,
    };

    try {
      setIsBankProceedLoader(true);
      fetch(PLAID_API_HOST + "/liquidity-token-exchange", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          ...(apiConfig?.headers ?? {}),
        },
        body: JSON.stringify(payload),
      })
      .then((res) => res.json())
        .then((response) => {
          setToken("");
          setIsBankProceedLoader(false);
          window.parent.postMessage(
            {
              type: "trigger",
              message: "success",
              data: {
                response,
                publicToken,
                metadata,
              },
            },
            "*"
          );
        })
        .catch((error) => {
          window.parent.postMessage(
            { type: "trigger", message: "error", data: error },
            "*"
          );
          setIsBankProceedLoader(false);
        });
    } catch (error) {
      setIsBankProceedLoader(false);
      window.parent.postMessage(
        { type: "trigger", message: "error", data: error },
        "*"
      );
    }
  },
  [apiConfig?.headers, setToken, token]
);

  const config: PlaidLinkOptions = {
    token,
    onSuccess,
    onExit: (error, metadata) => {
      window.parent.postMessage(
        { type: "trigger", message: "close", data: { error, metadata } },
        "*"
      );
    },
  };

  const { open } = usePlaidLink(config);

  return { open, token, isBankProceedLoader };
};
