import { useEffect, useState } from "react";
import { Loader } from "@storybook";
import { useAppPlaidLink } from "./plaidLinkHook";
import { useSetRecoilState } from "recoil";
import { PLAID_API_HOST, PlaidTokenState } from "./states";

export const PlaidWebToMobile = () => {
  const [loader, setLoader] = useState(true);
  const [tokenResponse, setTokenResponse] = useState<any>(null);

  const setToken = useSetRecoilState(PlaidTokenState);

  // Get token and tenantToken from URL
  const urlParams = new URLSearchParams(window.location.search);
  const token = urlParams.get("token");
  const tenantToken = urlParams.get("tenantToken");

  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
      "tenant-authorization": tenantToken ?? "",
      "Content-Type": "application/json",
    },
  };

  const { open, isBankProceedLoader } = useAppPlaidLink(config);

  useEffect(() => {
    try {
      const payload = {
        language: "en",
        countryCodes: ["US"],
      };
      fetch(PLAID_API_HOST + "/liquidity-link-tokens", {
        method: "POST",
        headers: config.headers,
        body: JSON.stringify(payload),
      })
        .then((res) => res.json())
        .then((res) => {
          const { data: tokenResponse } = res ?? {};
          if (tokenResponse?.token) {
            setTokenResponse(tokenResponse);
            window.parent.postMessage(
              { type: "trigger", message: "progress", data: tokenResponse },
              "*"
            );
          }
        })
        .catch((err) => {
          window.parent.postMessage(
            { type: "trigger", message: "error", data: err },
            "*"
          );
        });
    } catch (error) {
      window.parent.postMessage(
        { type: "trigger", message: "error", data: error },
        "*"
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (tokenResponse?.token) {
      setToken(tokenResponse?.token);
      window.parent.postMessage(
        { type: "trigger", message: "progress", data: tokenResponse },
        "*"
      );
      open();
      setLoader(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open, tokenResponse?.token]);

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
        flexDirection: "column",
      }}
    >
      {loader && (
        <div>
          <Loader />
          <div
            style={{
              fontSize: "16px",
              color: "#000000",
              marginTop: "18px",
            }}
          >
            Preparing the setup to add the bank...
          </div>
        </div>
      )}

      {isBankProceedLoader && (
        <div>
          <Loader />
          <div
            style={{
              fontSize: "16px",
              color: "#000000",
              textAlign: "center",
              margin: "18px 24px",
            }}
          >
            Setting up your bank account with Liquidity is in progress...
          </div>
        </div>
      )}
    </div>
  );
};
